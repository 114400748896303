import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'

export const useDndSensors = () => {
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 3 },
  })
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
    keyboardCodes: {
      start: ['Space'],
      cancel: ['Escape'],
      end: ['Space'],
    },
  })
  const sensors = useSensors(pointerSensor, keyboardSensor)

  return sensors
}
