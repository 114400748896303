import * as React from 'react'
import cn from 'classnames'
import styles from './AddResourceButton.module.scss'
import { PlusCircleOutline } from '../../../icons/PlusCircleOutline'
import type { PropsWithoutChildren } from '../../../types/helpers'

export type AddResourceButtonProps = {
  /**
   * Text to go inside the button, something like `Add a discipline`
   */
  children: React.ReactNode
} & PropsWithoutChildren<React.ComponentPropsWithoutRef<'button'>>

export const AddResourceButton: React.VFC<AddResourceButtonProps> = (props) => {
  const { className, children, ...restProps } = props

  return (
    <button className={cn(styles.addResourceButton, className)} {...restProps}>
      <span className={styles.text}>{children}</span>
      <PlusCircleOutline className={styles.icon} aria-hidden="true" />
    </button>
  )
}
